import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { HomePage, SocialsPage, LeaderBoardsPageAoe2, LeaderBoardsPageAoe4, ClansPage } from "./pages";
import { CommunityInvitational, CoopCup, RobinHoodCup, CondottieroCup, HallOfFame, Gloria, Gloria2, ClanWar, LicClanWarPage, CappaSpada, MemorialCCP, ChampionsLeague, QualificazioniChampionsLeague, TorneoDei3Campioni, CoppaDelNonno, BestCoupleCup, DeathTournamentWFD } from "./pages/tournaments/";
import { TopBar, Footer, MatchDetails, CountdownTimer, CountdownTimerObs } from "./components";
import ModsPage from "./pages/university/ModsPage";
import BattleRoyal from "./pages/university/BattleRoyal";
import Aoe4 from "./pages/university/Aoe4";
import Profile from "./pages/Profile";
import CookieConsent from "react-cookie-consent";
import WheelPage from "./pages/WheelPage";

export default (
  <Router>
    <TopBar />
    <Route path="/" exact component={HomePage} />
    <Route path="/home" component={HomePage} />
    <Route path="/classifiche/aoe2" component={LeaderBoardsPageAoe2} />
    <Route path="/classifiche/aoe4" component={LeaderBoardsPageAoe4} />
    <Route path="/universita/mods" component={ModsPage} />
    <Route path="/universita/battleRoyal" component={BattleRoyal} />
    <Route path="/universita/aoe4" component={Aoe4} />
    <Route path="/clan" component={ClansPage} />
    <Route path="/tornei/tcs" component={CappaSpada} />
    <Route path="/tornei/communityInvitational" component={CommunityInvitational} />
    <Route path="/tornei/tdg" component={Gloria} />
    <Route path="/tornei/tdg2" component={Gloria2} />
    <Route path="/tornei/coopCup" component={CoopCup} />
    <Route path="/tornei/robinHoodCup" component={RobinHoodCup} />
    <Route path="/tornei/condottieroCup" component={CondottieroCup} />
    <Route path="/tornei/LicClanWar" component={LicClanWarPage} />
    <Route path="/tornei/ClanWar" component={ClanWar} />
    <Route path="/tornei/memorialCCP" component={MemorialCCP} />
    <Route path="/tornei/championsLeague" component={ChampionsLeague} />
    <Route path="/tornei/qualificazioniChampionsLeague" component={QualificazioniChampionsLeague} />
    <Route path="/tornei/torneoDei3Campioni" component={TorneoDei3Campioni} />
    <Route path="/tornei/coppaDelNonno" component={CoppaDelNonno} />
    <Route path="/tornei/hallOfFame" component={HallOfFame} />
    <Route path="/tornei/bestCoupleCup" component={BestCoupleCup} />
    <Route path="/tornei/DeathTournamentWFD" component={DeathTournamentWFD} />
    <Route path="/profile" component={Profile} />
    <Route path="/social" component={SocialsPage} />
    <Route path="/partita/:id" component={MatchDetails} />
    <Route path="/countdown/:id" component={CountdownTimer} />
    <Route path="/countdownobs/:id" component={CountdownTimerObs} />
    <Route path="/ruota" component={WheelPage} />
    <Route
      path="/facebook"
      component={() => {
        window.location.href = "http://facebook.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/twitch"
      component={() => {
        window.location.href = "http://twitch.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/youtube"
      component={() => {
        window.location.href = "http://youtube.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/discord"
      component={() => {
        window.location.href = "http://discord.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/etsy"
      component={() => {
        window.location.href = "http://shop.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/telegram"
      component={() => {
        window.location.href = "http://telegram.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/whatsapp"
      component={() => {
        window.location.href = "http://whatsapp.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/shop"
      component={() => {
        window.location.href = "http://shop.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/store"
      component={() => {
        window.location.href = "http://shop.aoeitalia.com";
        return null;
      }}
    />
    <Route
      path="/patreon"
      component={() => {
        window.location.href = "http://patreon.aoeitalia.com";
        return null;
      }}
    />
    <CookieConsent
      location="bottom"
      buttonText="Ok, I understand."
      style={{ background: "#e9ecef", color: "black" }}
      buttonStyle={{ background: "#057bdd", color: "white", fontSize: "13px" }}
      expires={150}>Questo sito web usa cookies per migliorare l'esperienza dell'utente - This website uses cookies to enhance the user experience.</CookieConsent>
    <Footer />
  </Router>
);
